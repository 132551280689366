<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="open_dialog(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="6" md="2" class="pb-0 py-1">
                  Nombre
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    tabindex="1"
                    hide-details
                    autofocus
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2" class="pb-0 py-1">
                  Alias
                  <v-text-field
                    v-model.trim="filtro.alias"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="3" class="py-1">
                  <!-- <v-checkbox
                    v-model="filtro.online"
                    label="Cobro online"
                    class="mb-n1"
                    tabindex="1"
                    :true-value="1"
                    :false-value="null"
                    hide-details
                    dense
                  ></v-checkbox> -->
                  Cobro Online
                  <v-autocomplete
                      v-model="filtro.online"
                      item-text="cobro_nombre"
                      item-value="cobro_codigo"
                      tabindex="1"
                      :items="cobrosOnline"
                      hide-details
                      outlined
                      dense
                      clearable
                    ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="3" class="pb-0 py-1">
                  <!-- <v-checkbox
                    v-model="filtro.lapos"
                    label="Lapos integrado"
                    class="mb-n1"
                    tabindex="1"
                    :true-value="1"
                    :false-value="null"
                    hide-details
                    dense
                  ></v-checkbox> -->
                  Lapos Integrados
                  <v-autocomplete
                      v-model="filtro.lapos"
                      item-text="lapos_nombre"
                      item-value="lapos_codigo"
                      tabindex="1"
                      :items="laposIntegrados"
                      hide-details
                      outlined
                      dense
                      clearable
                    ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="2" class="pb-0 d-flex justify-star align-center">
                  <v-switch
                    v-model="filtro.inhabilitada"
                    label="Inhabilitada"
                    class="mb-2"
                    tabindex="1"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <v-col cols="12" class="mt-0 pt-0 d-flex justify-end">
                  <BtnFiltro class="pt-0"
                    :loading="load"
                    @clear="limpiar()"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="nombre"
        :headers="headers"
        :items="tarjetas"
        :loading="load"
        :search="search"
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.debito`]="{ item }">
          <v-icon
            v-if="item.debito == 1"
            color="success"
            title="Débito"
            small
          >
            fas fa-check-circle
          </v-icon>
        </template>
        <template v-slot:[`item.online`]="{ item }">
          <v-icon
            v-if="item.online == 1"
            color="success"
            title="Cobro online"
            small
          >
            fas fa-check-circle
          </v-icon>
        </template>
        <template v-slot:[`item.lapos_int`]="{ item }">
          <v-icon
            v-if="item.lapos_int == 1"
            color="success"
            title="Lapos integreado"
            small
          >
            fas fa-check-circle
          </v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="!item.inhabilitada"
            color="primary"
            title="Editar"
            class="mr-2"
            small
            @click="open_dialog(item)"
          >
            fas fa-pen
          </v-icon>
          <BtnConfirmar
            :icono="item.inhabilitada ? 'fas fa-check' : 'fas fa-ban'"
            :color="item.inhabilitada ? 'success' : 'error'"
            :title="item.inhabilitada ? 'Habilitar' : 'Inhabilitar'"
            :texto="item.inhabilitada
              ? `¿Desea habilitar la tarjeta <strong>${item.nombre}</strong>?`
              : `¿Desea inhabilitar la tarjeta <strong>${item.nombre}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="item.inhabilitada ? habilitar(item) : inhabilitar(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <Tarjeta
      v-model="dialog"
      :p_nueva="nueva"
      :p_tarjeta="tarjeta"
      @editar="editar"
    />
  </v-row>
</template>

<script>
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import SearchDataTable from '../../components/util/SearchDataTable'
import Tarjeta from '../../components/auditoria/Tarjeta'
import laposIntegrado from '../../modules/laposIntegrado';

export default {
  data () {
    return {
      panel: 0,
      search: '',
      load: false,
      nueva: false,
      dialog: false,
      tarjetas: [],
      headers: [
        { text: 'Nombre', value: 'nombre' },
        { text: 'Alias', value: 'alias' },
        { text: 'Cant. dígitos', value: 'cant_digitos', align: 'end' },
        { text: 'Dígitos autoriz.', value: 'digitos_autoriz', align: 'end' },
        { text: 'Medio pago Prisma', value: 'prisma_cod', align: 'end' },
        { text: 'Débito', value: 'debito', align: 'center', sortable: false, filterable: false },
        { text: 'Cobro online', value: 'online', align: 'center', sortable: false, filterable: false },
        { text: 'Lapos integrado', value: 'lapos_int', align: 'center', sortable: false, filterable: false  },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false },
      ],
      tarjeta: {},
      filtro: {
        nombre: '',
        alias: '',
        online: null,
        lapos: null,
        inhabilitada: 0
      },
      cobrosOnline:[
        { cobro_nombre: 'CON COBRO ONLINE', cobro_codigo: '1' }, 
        { cobro_nombre: 'SIN COBRO ONLINE', cobro_codigo: '0' }, 
        { cobro_nombre: 'TODOS', cobro_codigo:'2' }
      ],
      laposIntegrados:[
        { lapos_nombre: 'CON LAPOS', lapos_codigo: '1' }, 
        { lapos_nombre: 'SIN LAPOS', lapos_codigo: '0' }, 
        { lapos_nombre: 'TODOS', lapos_codigo:'2' }
      ]
    }
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    SearchDataTable,
    Tarjeta
  },
  methods: {
    async habilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('tarjetas/habilitar', item.codigo)
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          // "elimina" la tarjeta de la lista
          this.quitar(item)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async inhabilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('tarjetas/inhabilitar', item.codigo)
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          // "elimina" la tarjeta de la lista
          this.quitar(item)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      this.load = true
      this.search = ''
      this.tarjetas = [];
      await this.$store.dispatch('tarjetas/get_tarjetas', {
        nombre: this.filtro.nombre,
        alias: this.filtro.alias,
        online: this.filtro.online == 2 || this.filtro.online == null ? null : this.filtro.online,
        lapos: this.filtro.lapos == 2 || this.filtro.lapos == null ? null : this.filtro.lapos,
        inhabilitada: this.filtro.inhabilitada
      })
        .then((res) => {
          this.tarjetas = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    editar (item) {
      // actualiza la tarjeta en la lista
      let tarjeta = this.tarjetas.find(tar => tar.codigo == item.codigo)
      Object.assign(tarjeta, item)
      // si la inhabilito la quita de la lista
      if (tarjeta.inhabilitada == 1) {
        this.quitar(tarjeta)
      }
    },
    quitar (item) {
      const index = this.tarjetas.indexOf(item)
      this.tarjetas.splice(index, 1)
    },
    open_dialog (item) {
      if (item) {
        this.nueva = false
        this.tarjeta = JSON.parse(JSON.stringify(item))
      } else {
        this.nueva = true
        this.tarjeta = {}
      }
      this.dialog = true
    },
    limpiar () {
      this.filtro = {
        nombre: '',
        alias: '',
        online: null,
        lapos: null,
        inhabilitada: 0
      }
    }
  }
}
</script>