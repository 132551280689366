<template>
  <v-dialog
    v-model="dialog"
    max-width="550px"
    :persistent="load"
  >
    <v-card :disabled="load">
      <v-card-title>
        {{ p_nueva ? 'Nueva tarjeta' : 'Editar tarjeta' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-8">
        <v-form ref="form">
          <v-row class="pb-2">
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="tarjeta.nombre"
                label="Nombre"
                :rules="[rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="tarjeta.alias"
                label="Alias"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="4" class="py-0">
              <v-text-field
                v-model="tarjeta.cant_digitos"
                label="Cant. dígitos"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="4" class="py-0">
              <v-text-field
                v-model="tarjeta.digitos_autoriz"
                label="Dígitos autoriz."
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="4" class="py-0">
              <v-text-field
                v-model="tarjeta.prisma_cod"
                label="Medio pago Prisma"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              v-if="!p_nueva"
              cols="6" md="4"
              class="d-flex py-0"
            >
              <v-switch
                v-model="tarjeta.inhabilitada"
                label="Inhabilitada"
                class="mt-1"
                :true-value="1"
                :false-value="0"
                hide-details
                dense
              ></v-switch>
            </v-col>
            <v-col cols="6" md="4" class="py-0">
              <v-checkbox
                v-model="tarjeta.online"
                label="Cobro online"
                :true-value="1"
                :false-value="null"
                hide-details
                dense
              ></v-checkbox>
            </v-col>
            <v-col cols="6" md="4" class="py-0">
              <v-checkbox
                v-model="tarjeta.lapos_int"
                label="Lapos integrado"
                :true-value="1"
                :false-value="null"
                hide-details
                dense
              ></v-checkbox>
            </v-col>
            <v-col cols="6" md="4" class="py-0">
              <v-checkbox
                v-model="tarjeta.debito"
                label="Débito"
                :true-value="1"
                :false-value="null"
                hide-details
                dense
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end py-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para editar o crear una tarjeta
 * 
 *  Notas: este componente emite acciones (@nueva, @editar) al padre caundo se crea o edita una tarjeta (en caso de estar definidas)
 *         todos los props son obligatorios
 */
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      tarjeta: {},
      rules: {
        required: value => !!value || 'Campo requerido',
      }
    }
  },
  props: {
    value: Boolean,
    p_nueva: Boolean, // para editar una tarjeta enviar en false, para una tarjeta nueva en true
    p_tarjeta: Object // en caso de ser nueva enviar el objeto vacio {}
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    dialog (val) {
      if (val) {
        if (this.p_nueva) {
          // si es nueva setea los campos en null
          this.tarjeta = {
            codigo: null,
            nombre: null,
            alias: null,
            cant_digitos: null,
            digitos_autoriz: null,
            prisma_cod: null,
            debito: null,
            online: null,
            lapos_int: null,
          }
        } else {
          // crea una copia del prop p_tarjeta para editarlo
          this.tarjeta = JSON.parse(JSON.stringify(this.p_tarjeta))
        }
      } else {
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        this.load = true
        if (this.p_nueva) {
          await this.$store.dispatch('tarjetas/nueva', this.tarjeta)
            .then((res) => {
              this.dialog = false
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success',
              })
              // emite la accion 'nueva' definifa en el padre
              this.$emit('nueva', res.tarjeta)
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        } else {
          // solo edita si realmente se modifico la tarjeta
          if (JSON.stringify(this.tarjeta) != JSON.stringify(this.p_tarjeta)) {
            await this.$store.dispatch('tarjetas/editar', this.tarjeta)
              .then((res) => {
                this.dialog = false
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success',
                })
                // hace un UPPER del nombre y el alias de la tarjeta
                this.tarjeta.nombre = this.tarjeta.nombre.toUpperCase()
                this.tarjeta.alias = this.tarjeta.alias.toUpperCase()
                // emite la accion 'editar' definifa en el padre
                this.$emit('editar', this.tarjeta)
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          } else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en la tarjeta: el nombre, el alias y los demás valores son iguales a los anteriores',
              color: 'warning',
              timeout: 4000
            })
          }
        }
        this.load = false
      }
    }
  }
}
</script>